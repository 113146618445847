// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Agency from "../../../models/Agency.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              agencies: field.required("agencies", Json_Decode$JsonCombinators.array(Agency.Dashboard.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalAgencies: field.required("totalAgencies", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, sortBy, query) {
  var page = "page=" + String(pageNum);
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "TsdName" ? "&sort_by=tsds.name&sort_direction=" + toString(sortBy.VAL) : (
      variant === "ContractStart" ? "&sort_by=agencies.contract_start&sort_direction=" + toString(sortBy.VAL) : (
          variant === "Name" ? "&sort_by=agencies.name&sort_direction=" + toString(sortBy.VAL) : "&sort_by=agencies.active&sort_direction=" + toString(sortBy.VAL)
        )
    );
  return Rest.$$fetch("/dashboard/agencies?" + (page + (query$1 + sortBy$1)), "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
