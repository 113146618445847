// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as RestBrowser from "../../RestBrowser.res.js";
import * as ActiveModelError from "../../../models/ActiveModelError.res.js";

function exec(tsdId, name, active, description, contractStart, contract) {
  var formData = new FormData();
  formData.append("tsd_id", tsdId);
  formData.append("name", name);
  formData.append("active", active);
  formData.append("description", description);
  formData.append("contract_start", contractStart);
  if (contract !== undefined) {
    formData.append("contract_resource", contract);
  } else {
    formData.append("no_contract", undefined);
  }
  return RestBrowser.$$fetch("/dashboard/agencies", formData, {
              NAME: "Json",
              VAL: ID.Id.decoder
            }, {
              NAME: "Message",
              VAL: ActiveModelError.Errors.decoder
            }, "Post", undefined);
}

var $$FormData$1;

export {
  $$FormData$1 as $$FormData,
  exec ,
}
/* ID Not a pure module */
