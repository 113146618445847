// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateAgencyDashboardRequest from "./requests/CreateAgencyDashboardRequest.res.js";
import * as UpdateAgencyDashboardRequest from "./requests/UpdateAgencyDashboardRequest.res.js";
import * as FetchAgenciesDashboardRequest from "./requests/FetchAgenciesDashboardRequest.res.js";

var Dashboard = {
  index: FetchAgenciesDashboardRequest.exec,
  create: CreateAgencyDashboardRequest.exec,
  update: UpdateAgencyDashboardRequest.exec
};

export {
  Dashboard ,
}
/* CreateAgencyDashboardRequest Not a pure module */
